













































































import {Modal} from '@simpli/vue-modal'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {InputCheckbox, InputText} from '@simpli/vue-input'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {TokenPricedCollection} from '@/model/collection/TokenPricedCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {DappConnection} from '@/model/resource/DappConnection'
import {PlatformType} from '@/model/resource/PlatformType'
import {FilterTokenPricedSchema} from '@/schema/resource/TokenPriced/FilterTokenPricedSchema'
import {$} from '@/facade'
import TokenPricedVisibleToggleButton from '@/components/TokenPricedVisibleToggleButton.vue'
import TokenPricedDeprecatedToggleButton from '@/components/TokenPricedDeprecatedToggleButton.vue'

@Component({
  components: {
    TokenPricedDeprecatedToggleButton,
    TokenPricedVisibleToggleButton,
    Modal,
    InputCheckbox,
    InputText,
  },
})
export default class FilterTokenPricedMobile extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: TokenPricedCollection

  schema = new FilterTokenPricedSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
    ]

    await $.await.run('softQuery', () => Promise.all(promises))
  }

  hasValue(list = [], value: number) {
    return list.some((item: never) => item === value)
  }

  addVersion(param: number) {
    this.collection.idBlockchainVersionFk = param || null
  }

  async doFilter() {
    this.$emit('submit')
    await $.await.run('softQuery', () => this.collection.queryAsPage())
  }

  doClear() {
    this.collection.blockchainVersion = null
    this.collection.search = ''
    this.doFilter()
  }
}
