




















































































































































import {Component, Vue} from 'vue-property-decorator'
import {ListTokenPricedSchema} from '@/schema/resource/TokenPriced/ListTokenPricedSchema'
import TokenPricedModal from '@/components/TokenPricedModal.vue'
import {TokenPriced} from '@/model/resource/TokenPriced'
import {TokenPricedCollection} from '@/model/collection/TokenPricedCollection'
import {InputCheckbox} from '@simpli/vue-input'
import FilterTokenPriced from '@/components/filters/FilterTokenPriced.vue'
import FilterToggle from '@/components/FilterToggle.vue'
import {$} from '@/facade'

@Component({
  components: {
    FilterToggle,
    FilterTokenPriced,
    TokenPricedModal,
    InputCheckbox,
  },
})
export default class ListTokenPricedView extends Vue {
  schema = new ListTokenPricedSchema()
  collection = new TokenPricedCollection()
  filterOpen = false

  applyCollection() {
    this.collection.orderBy = 'order'
    this.collection.asc = false
    this.collection.isDeprecated = false
    this.collection.isVisible = true
  }

  async mounted() {
    this.applyCollection()
    await this.populateResources()
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [this.collection.expand()]

    await $.await.run('hardQuery', () => Promise.all(promises))
  }

  async changeOrder(item: TokenPriced, increment: number) {
    if (item.order === null) {
      item.order = 0
    }

    item.order += increment
    await item.changeOrder()
    $.toast.success('system.success.persist')
    await this.populate()
  }

  async changeDeprecated(item: TokenPriced) {
    await item.changeDeprecated()
    $.toast.success('system.success.persist')
    await this.populate()
  }

  async changeVisible(item: TokenPriced) {
    await item.changeIsVisible()
    $.toast.success('system.success.persist')
    await this.populate()
  }

  async populate() {
    await $.await.run('hardQuery', () => this.collection.queryAsPage())
  }
}
